<template>
  <div id="addRequistion" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">REQUISICIÓN</v-row>
      <v-container>
        <v-card>
          <v-tabs
            v-model="tabb"
            background-color="orange"
            color="white"
            grow
            centered
            active-class="amber accent-4"
          >
            <v-tab ripple href="#solicitante"> Solicitante </v-tab>
            <v-tab ripple href="#puesto"> Puesto </v-tab>
            <v-tab ripple href="#requisiciones"> Requisiciones </v-tab>
            <v-tab ripple href="#funciones"> Funciones </v-tab>
            <v-tab ripple href="#habilidades"> Habilidades </v-tab>
            <v-tab ripple href="#fecha"> Fecha Requerida de Ingreso </v-tab>
            <!--TAB DE SOLICITANTE-->
            <v-tab-item value="solicitante">
              <v-card text outlined elevation color="#d6d4d4">
                <v-card-text class="addAuthorization">
                  <v-row no-gutters justify="end" class="formAuthorization">
                    <v-col cols="12" lg="5" md="4" sm="6" class="text-left">
                      <div class="form-group">
                        <label for="razon">Razón social</label>
                        <select
                          name="razon"
                          id="razon"
                          v-model="enterprise"
                          @change="
                            obtenerAreas();
                            obtenerEmpleados();
                          "
                        >
                          <option disabled value="0">Razón social</option>
                          <option
                            v-for="enterprise in enterprises"
                            :key="enterprise.id"
                            v-bind:value="enterprise.id"
                          >
                            {{ enterprise.razonSocial }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="area">Área solicitante</label>
                        <select
                          name="area"
                          id="area"
                          v-model="area"
                          @change="obtenerPuestos()"
                        >
                          <option disabled value="0">Área solicitante</option>
                          <option
                            v-for="area in areas"
                            :key="area.id"
                            v-bind:value="area.id"
                          >
                            {{ area.nombreArea }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="puesto">Puesto vacante</label>
                        <select
                          name="puesto"
                          id="puesto"
                          v-model="puesto"
                          @change="obtenerNombrePuesto()"
                        >
                          <option selected disabled value="0">
                            Seleccione Puesto
                          </option>
                          <option
                            v-for="puesto in jobs"
                            :key="puesto.id"
                            v-bind:value="puesto.id"
                          >
                            {{ puesto.nombrePuesto }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="numeroVacante">Número de vacante</label>
                        <input
                          type="text"
                          name="numeroVacante"
                          id="numeroVacante"
                          placeholder="Obligatorio"
                          class="inputs"
                          autocomplete="false"
                          v-model="numeroVacante"
                          required
                          maxlength="2"
                          @keypress="isNumber($event)"
                          @change="validarVacantes()"
                        />
                      </div>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="6" class="mt-12">
                      <button class="botonAmarillo mt-5" @click="inicio()">
                        Continuar
                      </button>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!--TAB DE PUESTO-->
            <v-tab-item value="puesto">
              <v-card text outlined elevation color="#d6d4d4">
                <v-card-text class="puesto">
                  <v-container>
                    <v-row no-gutters justify="start">
                      <!--CAMPOS-->
                      <v-col class="text-left" cols="12" lg="6" md="6" sm="6">
                        <!--NOMBRE DEL PUESTO-->
                        <v-row>
                          <v-col>
                            <label for="nombrePuesto">Nombre del puesto</label>
                            <input
                              type="text"
                              name="nombrePuesto"
                              id="nombrePuesto"
                              placeholder="Obligatorio"
                              class="inputs"
                              autocomplete="false"
                              v-model="jobSelect.nombrePuesto"
                              required
                              disabled
                              maxlength="20"
                              @keypress="alfaNumerico($event)"
                            />
                          </v-col>
                        </v-row>
                        <!--JEFE INMEDIATO-->
                        <v-row>
                          <v-col>
                            <label for="jefe">Jefe inmediato</label>
                            <input
                              type="text"
                              name="jefe"
                              id="jefe"
                              placeholder="Obligatorio"
                              class="inputs"
                              autocomplete="false"
                              v-model="jobSelect.puestoReportaId"
                              required
                              disabled
                              maxlength="20"
                              @keypress="alfaNumerico($event)"
                            />
                          </v-col>
                        </v-row>
                        <!--TIPO DE CONTRATO-->
                        <v-row>
                          <v-col>
                            <label for="contrato">Tipo de contrato</label>
                            <select
                              name="contrato"
                              id="contrato"
                              v-model="contrato"
                            >
                              <option disabled value="0">
                                Selecciona Contrato
                              </option>
                              <option
                                v-for="contrato in contratos"
                                :key="contrato.id"
                                v-bind:value="contrato.id"
                              >
                                {{ contrato.tipoContrato }}
                              </option>
                            </select>
                          </v-col>
                        </v-row>
                        <!--TIPO DE SALARIO-->
                        <v-row>
                          <v-col>
                            <label for="salario">Tipo de salario</label>
                            <select
                              name="salario"
                              id="salario"
                              v-model="salario"
                            >
                              <option disabled value="0">
                                Selecciona salario
                              </option>
                              <option
                                v-for="salario in salarios"
                                :key="salario.id"
                                v-bind:value="salario.id"
                              >
                                {{ salario.tipoSalario }}
                              </option>
                            </select>
                          </v-col>
                        </v-row>
                        <!--RANGO DE SUELDO-->
                        <v-row class="dosSelect">
                          <v-col>
                            <label for="tipo">Tipo de sueldo</label>
                            <select
                              name="tipo"
                              id="tipo"
                              class="mr-4"
                              v-model="rango"
                            >
                              <option disabled value="0">Seleccione</option>
                              <option
                                v-for="rango in rangos"
                                :key="rango.id"
                                v-bind:value="rango.id"
                              >
                                {{ rango.nombre }}
                              </option>
                            </select>
                            <select
                              name="select2"
                              id="select2"
                              class="ml-3"
                              v-model="neto"
                            >
                              <option disabled value="0">Seleccione</option>
                              <option
                                v-for="neto in netos"
                                :key="neto.id"
                                v-bind:value="neto.id"
                              >
                                {{ neto.nombre }}
                              </option>
                            </select>
                          </v-col>
                        </v-row>
                        <!--ESPECIFICACION DE SUELDO-->
                        <v-row>
                          <v-col class="dosSelect">
                            <label for="rango1">Rango de sueldo</label>
                            <input
                              type="text"
                              name="rango1"
                              id="rango1"
                              placeholder="Obligatorio"
                              class="inputs mr-9"
                              autocomplete="false"
                              v-model="r1"
                              required
                              maxlength="6"
                              @keypress="isNumberPoint($event)"
                            />A
                            <input
                              type="text"
                              name="rango2"
                              id="rango2"
                              placeholder="Obligatorio"
                              class="inputs ml-9"
                              autocomplete="false"
                              v-model="r2"
                              required
                              maxlength="6"
                              @keypress="isNumberPoint($event)"
                            />
                          </v-col>
                        </v-row>
                        <!--TIPO DE VACANTE-->
                        <v-row>
                          <v-col>
                            <label for="vacante">Tipo de vacante</label>
                            <select
                              name="vacante"
                              id="job"
                              v-model="vacante"
                              class="mr-4"
                            >
                              <option disabled value="0">
                                Selecciona vacante
                              </option>
                              <option
                                v-for="vacante in vacantes"
                                :key="vacante.id"
                                v-bind:value="vacante.id"
                              >
                                {{ vacante.tipoVacante }}
                              </option>
                            </select>
                          </v-col>
                        </v-row>
                        <!--PRESTACIONES-->
                        <v-row>
                          <v-col>
                            <label for="prestacion">Prestaciones</label>

                            <select
                              name="prestacion"
                              id="prestacion"
                              v-model="prestacion"
                            >
                              <option disabled value="0">
                                Selecciona prestación
                              </option>
                              <option
                                v-for="prestacion in prestaciones"
                                :key="prestacion.id"
                                v-bind:value="prestacion.id"
                              >
                                {{ prestacion.nombrePaquete }}
                              </option>
                            </select>
                          </v-col>
                        </v-row>
                        <!--RANGO DE EDAD-->
                        <v-row class="dosSelect">
                          <v-col>
                            <label for="rango">Rango de Edad</label>
                            <input
                              type="text"
                              name="edad1"
                              id="edad1"
                              placeholder="Obligatorio"
                              class="inputs mr-9"
                              autocomplete="false"
                              v-model="edad1"
                              required
                              minlength="2"
                              maxlength="2"
                              @keypress="isNumber($event)"
                            />A
                            <input
                              type="text"
                              name="edad2"
                              id="edad2"
                              placeholder="Obligatorio"
                              class="inputs ml-9"
                              autocomplete="false"
                              v-model="edad2"
                              required
                              minlength="2"
                              maxlength="2"
                              @keypress="isNumber($event)"
                            />
                          </v-col>
                        </v-row>
                        <!--GENERO-->
                        <v-row>
                          <v-col>
                            <label for="job">Género</label>
                            <select name="job" id="job" v-model="genero">
                              <option disabled value="0">
                                Selecciona género
                              </option>
                              <option
                                v-for="genero in generos"
                                :key="genero.id"
                                v-bind:value="genero.id"
                              >
                                {{ genero.nombreGenero }}
                              </option>
                            </select>
                          </v-col>
                        </v-row>
                        <!--EXPERIENCIA-->
                        <v-row>
                          <v-col cols="12" md sm="10" xl="8" lg="8">
                            <label for="rango" class="mb-3"
                              >Experiencia en años</label
                            >
                            <v-range-slider
                              v-model="range"
                              :max="maxp"
                              :min="minp"
                              hide-details
                              class="align-center"
                              :thumb-size="17"
                              thumb-label="always"
                              color="orange"
                            ></v-range-slider>
                          </v-col>
                        </v-row>
                        <!--EXPERIENCIA EN EL PUESTO-->
                        <v-row>
                          <v-col cols="12" md sm="10" xl="8" lg="8">
                            <label
                              for="rangop"
                              class="mb-3"
                              style="width: 250px !important"
                              >Experiencia en el puesto en años</label
                            >
                            <v-range-slider
                              v-model="rangep"
                              :max="maxp"
                              :min="minp"
                              hide-details
                              class="align-center"
                              :thumb-size="17"
                              thumb-label="always"
                              color="orange"
                            ></v-range-slider>
                          </v-col>
                        </v-row>
                        <!--ESTADO CIVIL-->
                        <v-row class="dosSelect">
                          <v-col>
                            <label for="estado">Estado Civil</label>
                            <select
                              name="estado"
                              id="estado"
                              class="mr-4"
                              v-model="estado"
                            >
                              <option disabled value="0">Selecciona</option>
                              <option
                                v-for="estado in estados"
                                :key="estado.id"
                                v-bind:value="estado.id"
                              >
                                {{ estado.nombreEstadoCivil }}
                              </option></select
                            >Escolaridad
                            <select
                              name="select2"
                              id="select2"
                              class="ml-3"
                              v-model="escolaridad"
                            >
                              <option disabled value="0">Selecciona</option>
                              <option
                                v-for="escolaridad in escolaridades"
                                :key="escolaridad.id"
                                v-bind:value="escolaridad.id"
                              >
                                {{ escolaridad.nombreEscolaridad }}
                              </option>
                            </select>
                          </v-col>
                        </v-row>
                        <!--BOTON DE IDIOMAS -->
                        <v-row>
                          <v-col>
                            <label for="rango">Idioma</label>
                            <v-btn
                              class="mt-1"
                              id="restriccion"
                              text
                              icon
                              color="#00287c"
                              @click="cargaIdioma()"
                            >
                              <v-icon>add_box</v-icon>
                            </v-btn>
                            <v-btn
                              class="mt-1"
                              id="quitarIdiom"
                              text
                              icon
                              color="#00287c"
                              @click="quitarIdioma()"
                            >
                              <v-icon>remove_circle</v-icon></v-btn
                            >
                          </v-col>
                        </v-row>
                        <!--LISTADO DE IDIOMAS-->
                        <v-row
                          v-for="idioma in arregloIdioma"
                          v-bind:value="idioma.id"
                          :key="idioma.id"
                          class="dosSelect"
                        >
                          <v-col>
                            <label for="idioma">Idioma</label>
                            <select
                              name="rango"
                              id="rango"
                              class="mr-4"
                              v-model="idioma.idiomaId"
                            >
                              <option disabled value="0">
                                Selecciona Idioma
                              </option>
                              <option
                                v-for="idiom in idiomas"
                                :key="idiom.id"
                                v-bind:value="idiom.id"
                              >
                                {{ idiom.nombreIdioma }}
                              </option></select
                            >Nivel
                            <select
                              name="select2"
                              id="select2"
                              class="ml-3"
                              v-model="idioma.nivelIdiomaId"
                            >
                              <option disabled value="0">Nivel</option>
                              <option
                                v-for="nivel in niveles"
                                :key="nivel.id"
                                v-bind:value="nivel.id"
                              >
                                {{ nivel.nombreNivelIdioma }}
                              </option>
                            </select>
                          </v-col>
                        </v-row>
                        <!--BOTON DE SOFTWARE -->
                        <v-row>
                          <v-col>
                            <label for="software">Manejo de Software</label>
                            <v-btn
                              class="mt-1"
                              id="restriccionSoftware"
                              text
                              icon
                              color="#00287c"
                              @click="cargaSoftware()"
                            >
                              <v-icon>add_box</v-icon>
                            </v-btn>
                            <v-btn
                              class="mt-1"
                              id="quitarSoft"
                              text
                              icon
                              color="#00287c"
                              @click="quitarSoftware()"
                              @blur="revisarEquipo()"
                            >
                              <v-icon>remove_circle</v-icon></v-btn
                            >
                          </v-col>
                        </v-row>
                        <!--LISTADO DE TIPO DE SOFTWARE-->
                        <v-row
                          v-for="tipo in arregloSoftware"
                          v-bind:value="tipo.id"
                          :key="tipo.id"
                          class="tresSelect"
                        >
                          <v-col>
                            <label for="nombreSoftware"
                              >Nombre de Software</label
                            >
                            <select
                              name="nombreSoftware"
                              id="nombreSoftware"
                              class="mr-2"
                              v-model="tipo.softwareId"
                            >
                              <option disabled value="0">Selecciona</option>
                              <option
                                v-for="software in softwares"
                                :key="software.id"
                                v-bind:value="software.id"
                              >
                                {{ software.nombreSoftware }}
                              </option></select
                            >Nivel
                            <select
                              name="rango1"
                              id="rango1"
                              class="ml-1 mr-1"
                              v-model="tipo.nivelSoftwareMinId"
                            >
                              <option disabled value="0">
                                Selecciona Rango
                              </option>
                              <option
                                v-for="nivel in softwareLevel"
                                :key="nivel.id"
                                v-bind:value="nivel.id"
                              >
                                {{ nivel.descripcionNivel }}
                              </option>
                            </select>
                            A
                            <select
                              name="rango2"
                              id="rango2"
                              class="ml-2"
                              v-model="tipo.nivelSoftwareMaxId"
                            >
                              <option disabled value="0">
                                Selecciona Rango
                              </option>
                              <option
                                v-for="nivel in softwareLevel"
                                :key="nivel.id"
                                v-bind:value="nivel.id"
                              >
                                {{ nivel.descripcionNivel }}
                              </option>
                            </select>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!--ACCIONES-->
                      <v-col
                        class="text-center"
                        cols="12"
                        lg="6"
                        md="6"
                        sm="6"
                        align-self="center"
                      >
                        <v-row justify="center">
                          <button
                            class="botonAmarilloDerecho"
                            @click="validacionInicial()"
                          >
                            Continuar
                          </button>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!--TAB DE REQUISICIONES-->
            <v-tab-item value="requisiciones">
              <v-card text outlined elevation color="#d6d4d4">
                <v-card-text class="addAuthorization">
                  <v-container>
                    <v-row no-gutters justify="start">
                      <!--CAMPOS-->
                      <v-col class="text-left" cols="12" lg="6" md="6" sm="6">
                        <!--BOTON DE EQUIPO -->
                        <v-row>
                          <v-col>
                            <label for="equipo">Equipo de Computo</label>
                            <v-btn
                              class="mt-1"
                              id="restriccionTrabajo"
                              text
                              icon
                              color="#00287c"
                              @click="validacionEquipo()"
                            >
                              <v-icon>add_box</v-icon>
                            </v-btn>
                            <v-btn
                              class="mt-1"
                              id="quitarEquipos"
                              text
                              icon
                              color="#00287c"
                              @click="quitarEquipo()"
                            >
                              <v-icon>remove_circle</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <!--LISTADO DE EQUIPO-->
                        <v-row
                          v-for="equipo in arregloEquipo"
                          v-bind:value="equipo.id"
                          :key="equipo.id"
                        >
                          <v-col>
                            <!--TIPO DE EQUIPO-->
                            <v-row class="mt-n5 mb-n5">
                              <v-col>
                                <label for="tipo">Tipo</label>
                                <select
                                  name="tipo"
                                  id="tipo"
                                  v-model="equipo.equipoComputoId"
                                >
                                  <option disabled value="0">
                                    Selecciona equipo
                                  </option>
                                  <option
                                    v-for="equipo in equipments"
                                    :key="equipo.id"
                                    v-bind:value="equipo.id"
                                  >
                                    {{ equipo.tipoEquipo }}
                                  </option>
                                </select>
                              </v-col>
                            </v-row>
                            <!--SISTEMA OPERATIVO-->
                            <v-row class="mt-n5 mb-n5">
                              <v-col>
                                <label for="tipo">Sistema Operativo</label>
                                <select
                                  name="tipo"
                                  id="tipo"
                                  v-model="equipo.sistemaOperativoId"
                                >
                                  <option disabled value="0">
                                    Selecciona sistema
                                  </option>
                                  <option
                                    v-for="sistema in systems"
                                    :key="sistema.id"
                                    v-bind:value="sistema.id"
                                  >
                                    {{ sistema.nombreSO }}
                                  </option>
                                </select>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <!--BOTON DE UNIFORMES -->
                        <v-row>
                          <v-col>
                            <label for="uniformes">Uniformes</label>
                            <v-btn
                              class="mt-1"
                              id="restriccionUniformes"
                              text
                              icon
                              color="#00287c"
                              @click="cargaUniforme()"
                            >
                              <v-icon>add_box</v-icon>
                            </v-btn>
                            <v-btn
                              class="mt-1"
                              id="quitarUniformes"
                              text
                              icon
                              color="#00287c"
                              @click="quitarUniforme()"
                            >
                              <v-icon>remove_circle</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <!--LISTADO DE UNIFORMES-->
                        <v-row
                          v-for="uniforme in arregloUniforme"
                          v-bind:value="uniforme.id"
                          :key="uniforme.id"
                        >
                          <v-col>
                            <v-row class="mt-n5 mb-n5">
                              <v-col>
                                <label for="talla">Uniforme</label>
                                <select
                                  name="talla"
                                  id="talla"
                                  class="mr-2"
                                  v-model="uniforme.uniformeId"
                                >
                                  <option disabled value="0">Selecciona</option>
                                  <option
                                    v-for="un in uniformes"
                                    :key="un.id"
                                    v-bind:value="un.id"
                                  >
                                    {{ un.nombreUniforme }}
                                  </option>
                                </select>
                              </v-col>
                            </v-row>
                            <v-row class="mt-n5 mb-n5">
                              <v-col>
                                <label for="talla">Talla</label>
                                <select
                                  name="talla"
                                  id="talla"
                                  class="mr-2"
                                  v-model="uniforme.tallaId"
                                >
                                  <option disabled value="0">Selecciona</option>
                                  <option
                                    v-for="talla in tallas"
                                    :key="talla.id"
                                    v-bind:value="talla.id"
                                  >
                                    {{ talla.tipoTalla }}
                                  </option>
                                </select>
                              </v-col>
                            </v-row>
                            <v-row class="mt-n5 mb-n5">
                              <v-col>
                                <label for="cantidad">Cantidad</label>
                                <input
                                  type="text"
                                  name="cantidad"
                                  id="cantidad"
                                  placeholder="Obligatorio"
                                  class="inputs"
                                  autocomplete="false"
                                  v-model="uniforme.cantidad"
                                  required
                                  maxlength="2"
                                  @keypress="isNumber($event)"
                                  style="
                                    display: inline-block;
                                    width: 180px !important;
                                  "
                              /></v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <!--BOTON DE EQUIPO DE TRABAJO -->
                        <v-row>
                          <v-col>
                            <label for="equipoTrabajo">Equipo de Trabajo</label>
                            <v-btn
                              class="mt-1"
                              id="restriccionEquipoTrabajo"
                              text
                              icon
                              color="#00287c"
                              @click="cargaTrabajo()"
                            >
                              <v-icon>add_box</v-icon>
                            </v-btn>
                            <v-btn
                              class="mt-1"
                              id="quitarEquipos"
                              text
                              icon
                              color="#00287c"
                              @click="quitarTrabajo()"
                            >
                              <v-icon>remove_circle</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <!--LISTADO DE EQUIPO-->
                        <v-row
                          v-for="herramienta in arregloTrabajo"
                          v-bind:value="herramienta.id"
                          :key="herramienta.id"
                        >
                          <v-col>
                            <!--TIPO DE EQUIPO DE TRABAJO-->
                            <v-row class="mt-n5 mb-n5">
                              <v-col>
                                <label for="herramienta">Herramienta</label>
                                <select
                                  name="herramienta"
                                  id="herramienta"
                                  v-model="herramienta.herramientaId"
                                >
                                  <option disabled value="0">
                                    Selecciona equipo
                                  </option>
                                  <option
                                    v-for="herramientaTrabajo in machines"
                                    :key="herramientaTrabajo.id"
                                    v-bind:value="herramientaTrabajo.id"
                                  >
                                    {{ herramientaTrabajo.nombreMaquina }}
                                  </option>
                                </select>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!--ACCIONES-->
                      <v-col
                        class="text-center"
                        cols="12"
                        lg="6"
                        md="6"
                        sm="6"
                        align-self="center"
                      >
                        <v-row justify="center">
                          <button
                            class="botonAmarilloDerecho"
                            @click="validacionRequisicion()"
                          >
                            Continuar
                          </button>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!--TAB DE FUNCIONES-->
            <v-tab-item value="funciones">
              <v-card text outlined elevation color="#d6d4d4">
                <v-card-text>
                  <v-row no-gutters justify="end" class="mb-2 mr-9">
                    <button
                      class="breakSearch botonAmarillo"
                      style="font-size: 0.95em"
                      @click="addFuncion = true"
                    >
                      Agregar Función
                    </button>
                  </v-row>
                  <v-row
                    no-gutters
                    class="datos pa-0"
                    style="height: auto !important"
                  >
                    <v-card
                      style="
                        width: 100%;
                        background: rgb(199, 195, 199) !important;
                      "
                    >
                      <v-data-table
                        :headers="headers"
                        :items="activities"
                        :search="search"
                        class="elevation-2 text--dark"
                        loading-text="Cargando datos... Por favor espere"
                        :items-per-page="5"
                        :no-results-text="'No se encontraron incidencias'"
                        :no-data-text="'No hay datos'"
                        :footer-props="{
                          'items-per-page-text': 'Actividades por página',
                          'items-per-page': [5, 10, 15],
                          'items-per-page-all-text': 'Todos',
                          'items-per-page-options': [5, 10],
                          'loading-text':
                            'Obteniendo datos... Por favor espere',
                          'no-data-text': 'No hay datos...',
                          'no-results-text': 'No se encontraron incidencias',
                        }"
                      >
                        <template v-slot:no-data>
                          <v-alert :value="true" color="#ffffff" icon="warning">
                            <p style="color: black">
                              No hay datos en sistema ...
                            </p>
                          </v-alert>
                        </template>
                        <template v-slot:body="{ items }">
                          <tbody>
                            <tr
                              v-for="item in items"
                              :key="item.id"
                              :class="{ selectedRow: item === selectedItem }"
                            >
                              <td class="text-sm-center">{{ item.id }}</td>
                              <td class="text-sm-center">
                                {{ item.descripcionActividad }}
                              </td>
                              <td class="text-sm-center">
                                {{ item.frecuencia }}
                              </td>
                              <td class="justify-center layout px-0">
                                <v-icon
                                  color="#717171"
                                  small
                                  class="mr-6"
                                  @click="editItemFuncion(item)"
                                  >fa-edit</v-icon
                                >
                                <v-icon
                                  color="red"
                                  small
                                  @click="temporalFuncion(item)"
                                  >fa-trash-alt</v-icon
                                >
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-row>
                  <v-row no-gutters justify="center" class="mb-2">
                    <v-col cols="12" lg="6" md="6" sm="6" class="mt-2">
                      <button
                        class="botonAmarillo mt-5"
                        @click="funcionFunciones()"
                      >
                        Continuar
                      </button>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!--TAB DE HABILIDADES-->
            <v-tab-item value="habilidades">
              <v-card text outlined elevation color="#d6d4d4">
                <v-card-text>
                  <v-row no-gutters justify="end" class="mb-2 mr-9">
                    <button
                      class="breakSearch botonAmarillo"
                      style="font-size: 0.95em"
                      @click="addHabilidad = true"
                    >
                      Nueva Habilidad
                    </button>
                  </v-row>
                  <v-row
                    no-gutters
                    class="datos pa-0"
                    style="height: auto !important"
                  >
                    <v-card
                      style="
                        width: 100%;
                        background: rgb(199, 195, 199) !important;
                      "
                    >
                      <v-data-table
                        :headers="habilitiesHeaders"
                        :items="habilities"
                        :search="search"
                        class="elevation-2 text--dark"
                        loading-text="Cargando datos... Por favor espere"
                        :items-per-page="5"
                        :no-results-text="'No se encontraron incidencias'"
                        :no-data-text="'No hay datos'"
                        :footer-props="{
                          'items-per-page-text': 'Habilidades por página',
                          'items-per-page': [5, 10, 15],
                          'items-per-page-all-text': 'Todos',
                          'items-per-page-options': [5, 10],
                          'loading-text':
                            'Obteniendo datos... Por favor espere',
                          'no-data-text': 'No hay datos...',
                          'no-results-text': 'No se encontraron incidencias',
                        }"
                      >
                        <template v-slot:no-data>
                          <v-alert :value="true" color="#ffffff" icon="warning">
                            <p style="color: black">
                              No hay datos en sistema ...
                            </p>
                          </v-alert>
                        </template>
                        <template v-slot:body="{ items }">
                          <tbody>
                            <tr
                              v-for="item in items"
                              :key="item.id"
                              :class="{ selectedRow: item === selectedItem }"
                            >
                              <td class="text-sm-center">{{ item.id }}</td>
                              <td class="text-sm-center">
                                {{ item.descripcionHabilidad }}
                              </td>
                              <td class="text-sm-center">
                                {{ item.frecuencia }}
                              </td>
                              <td class="justify-center layout px-0">
                                <v-icon
                                  color="#717171"
                                  small
                                  class="mr-6"
                                  @click="editItemHabilidad(item)"
                                  >fa-edit</v-icon
                                >
                                <v-icon
                                  color="red"
                                  small
                                  @click="temporalHabilidades(item)"
                                  >fa-trash-alt</v-icon
                                >
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-row>
                  <v-row no-gutters justify="center" class="mb-2">
                    <v-col cols="12" lg="6" md="6" sm="6" class="mt-2">
                      <button
                        class="botonAmarillo mt-5"
                        @click="funcionHabilidad()"
                      >
                        Continuar
                      </button>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!--TAB DE FECHA-->
            <v-tab-item value="fecha">
              <v-card text outlined elevation color="#d6d4d4">
                <v-card-text>
                  <v-row justify="space-around" align-content="center">
                    <v-col cols="12" sm="12" md="8" lg="8">
                      <v-date-picker
                        v-model="picker"
                        year-icon="mdi-calendar-blank"
                        prev-icon="mdi-skip-previous"
                        next-icon="mdi-skip-next"
                        locale="es"
                        :min="fecha_minimo"
                        color="orange lighten-1"
                      ></v-date-picker>
                    </v-col>
                  </v-row>
                  <v-row no-gutters justify="center" class="mb-2">
                    <v-col cols="12" lg="6" md="6" sm="6" class="mt-2">
                      <button
                        class="botonAmarillo mt-5"
                        @click="validacionFinal()"
                      >
                        Enviar
                      </button>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-container>
    </v-container>
    <!--Aceptación de acciones funcion-->
    <v-dialog v-model="adviceFuncion" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              adviceFuncion = false;
              id = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteFunciones()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Alerta de numero de vacantes-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="advice = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="advice = false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Alerta de agregado de funciones-->
    <v-dialog v-model="adviceFunciones" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="mantenerTab()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="cambiarTab()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Aceptación de acciones habilidad-->
    <v-dialog v-model="adviceHabilidad" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              adviceHabilidad = false;
              id = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteHabilidades()"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Agregar Funcion-->
    <v-dialog v-model="addFuncion" width="350">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Agregar Función</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <div class="form-group mt-9 mb-5">
              <label for="descripcion" class="mr-10">Descripción</label>
              <textarea
                type="text"
                name="descripcion"
                id="descripcion"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="descripcion"
                required
                maxlength="256"
                @keypress="alfaNumerico($event)"
              />
            </div>
          </v-row>
          <v-row class="mt-5">
            <div class="form-group">
              <label for="frecuencia" class="mr-11">Frecuencia</label>
              <select name="frecuencia" id="frecuencia" v-model="frecuencia">
                <option disabled value="0">Seleccione la frecuencia</option>
                <option value="Alta">Alta</option>
                <option value="Media">Media</option>
                <option value="Baja">Baja</option>
              </select>
              <br />
              <br />
              <label v-if="vacio" style="color: red"
                >*Debe especificar la descripción de las funciones</label
              >
              <br />
              <label v-if="vacio" style="color: red">y/o frecuencia</label>
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelFuncion()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="validacionFuncionNueva()"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Editar item-->
    <v-dialog v-model="editFuncion" width="350">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Edición de datos</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <div class="form-group mt-9 mb-5">
              <label for="descripcion" class="mr-10">Actividad</label>
              <textarea
                type="text"
                name="descripcion"
                id="descripcion"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="descripcion"
                required
                maxlength="256"
                @keypress="alfaNumerico($event)"
              />
            </div>
          </v-row>
          <v-row class="mt-5">
            <div class="form-group">
              <label for="frecuencia" class="mr-8">Frecuencia</label>
              <select name="frecuencia" id="frecuencia" v-model="frecuencia">
                <option disabled value="0">Seleccione la frecuencia</option>
                <option value="Alta">Alta</option>
                <option value="Media">Media</option>
                <option value="Baja">Baja</option>
              </select>
              <br />
              <br />
              <label v-if="vacio" style="color: red"
                >*Debe especificar la descripción de las funciones</label
              >
              <br />
              <label v-if="vacio" style="color: red">y/o frecuencia</label>
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="auxFuncion()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="validacionFuncion()"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Agregar Funcion-->
    <v-dialog v-model="addHabilidad" width="350">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Agregar Habilidad</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <div class="form-group mt-9 mb-5">
              <label for="descripcion" class="mr-10">Descripción</label>
              <textarea
                type="text"
                name="descripcion"
                id="descripcion"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="descripcion"
                required
                maxlength="256"
                @keypress="alfaNumerico($event)"
              />
            </div>
          </v-row>
          <v-row class="mt-5">
            <div class="form-group">
              <label for="frecuencia" class="mr-11">Frecuencia</label>
              <select name="frecuencia" id="frecuencia" v-model="frecuencia">
                <option disabled value="0">Seleccione la frecuencia</option>
                <option value="Alta">Alta</option>
                <option value="Media">Media</option>
                <option value="Baja">Baja</option>
              </select>
              <br />
              <br />
              <label v-if="vacio" style="color: red"
                >*Debe especificar la descripción de las funciones</label
              >
              <br />
              <label v-if="vacio" style="color: red">y/o frecuencia</label>
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelHabilidad()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="validacionHabilidadNueva()"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Editar item-->
    <v-dialog v-model="editHabilidad" width="350">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Edición de datos</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <div class="form-group mt-9 mb-5">
              <label for="descripcion" class="mr-10">Habilidad</label>
              <textarea
                type="text"
                name="descripcion"
                id="descripcion"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="descripcion"
                required
                maxlength="256"
                @keypress="alfaNumerico($event)"
              />
            </div>
          </v-row>
          <v-row class="mt-5">
            <div class="form-group">
              <label for="frecuencia" class="mr-8">Frecuencia</label>
              <select name="frecuencia" id="frecuencia" v-model="frecuencia">
                <option disabled value="0">Seleccione la frecuencia</option>
                <option value="Alta">Alta</option>
                <option value="Media">Media</option>
                <option value="Baja">Baja</option>
              </select>
              <br />
              <br />
              <label v-if="vacio" style="color: red"
                >*Debe especificar la descripción de la habilidad</label
              >
              <br />
              <label v-if="vacio" style="color: red">y/o frecuencia</label>
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="auxHabilidad()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="validacionHabilidad()"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Error-->
    <v-dialog v-model="errorFinal" scrollable width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="errorFinal = false"
            >Corregir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmationFinal" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmationFinal = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="avisoTermino" width="350">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">¿Datos correctos?</span>
        </v-card-title>
        <v-card-text class="mt-4">
          Esta seguro que todos los datos son correctos, una vez enviados no
          pueden ser modificados.</v-card-text
        >
        <v-card-actions>
          <v-btn color="red darken-1" text @click="avisoTermino = false"
            >No aceptar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              avisoTermino = false;
              terminos = true;
              obtenerArreglosFinales('completo');
            "
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      avisoTermino: false,
      expiration: false,
      picker: null,
      show: false,
      search: "",
      dialog: false,
      selectedItem: null,
      confirmationFinal: false,
      headers: [
        {
          text: "Funcion",
          align: "center",
          value: "id",
        },
        {
          text: "Descripción de las principales actividades",
          align: "center",
          value: "activity",
        },
        {
          text: "Frecuencia",
          align: "center",
          value: "frecuency",
        },
        {
          text: "Editar / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      habilitiesHeaders: [
        {
          text: "Funcion",
          align: "center",
          value: "id",
        },
        {
          text: "Habilidades, conocimientos y experiencias",
          align: "center",
          value: "activity",
        },
        {
          text: "Frecuencia",
          align: "center",
          value: "frecuency",
        },
        {
          text: "Editar / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      area: 0,
      areas: [],
      jefearea: 0,
      jefesarea: [],
      solicitantes: [],
      gerente: 0,
      gerentes: [],
      jefe: 0,
      directores: [],
      equipo: 0,
      equipments: [],
      errors: [],
      respuesta: "",
      titulo: "",
      id: "",
      confirmation: false,
      vacio: false,
      id: "",
      terminos: false,
      activities: [
        {
          id: 1,
          descripcionActividad: "Actividad 1",
          frecuencia: "Alta",
        },
        {
          id: 2,
          descripcionActividad: "Actividad 2",
          frecuencia: "Media",
        },
        {
          id: 3,
          descripcionActividad: "Actividad 3",
          frecuencia: "Baja",
        },
      ],
      habilities: [
        {
          id: 1,
          descripcionHabilidad: "Habilidad 1",
          frecuencia: "Alta",
        },
        {
          id: 2,
          descripcionHabilidad: "Habilidad 2",
          frecuencia: "Media",
        },
        {
          id: 3,
          descripcionHabilidad: "Habilidad 3",
          frecuencia: "Baja",
        },
      ],
      tabb: "solicitante",
      tab: null,
      items: [
        { menu: "Solicitante", content: "Solicitante" },
        { menu: "Puesto", content: "Puesto" },
        { menu: "Requisiciones", content: "Requisiciones" },
        { menu: "Funciones", content: "Funcion" },
        { menu: "Habilidades", content: "Habilidad" },
        { menu: "Fecha Requerida de Ingreso", content: "Fecha" },
      ],
      solicitante: localStorage.empleadoIdGlobal,
      director: 0,
      gerente: 0,
      puesto: 0,
      jobs: [],
      contrato: 0,
      contratos: [],
      neto: 0,
      netos: [
        {
          id: 1,
          nombre: "Bruto",
        },
        {
          id: 2,
          nombre: "Neto",
        },
      ],
      edad1: 0,
      edad2: 0,
      salario: 0,
      salarios: [],
      rango: 0,
      r1: 0,
      r2: 0,
      rangos: [
        {
          id: 1,
          nombre: "SM M",
        },
        {
          id: 2,
          nombre: "SM Pesos",
        },
      ],
      numeroVacante: 0,
      numeroVacanteAux: 0,
      vacante: 0,
      vacantes: [],
      idioma: 0,
      idiomas: [],
      niveles: [],
      escolaridad: 0,
      escolaridades: [],
      genero: 0,
      generos: [],
      estado: 0,
      estados: [],
      soft: 0,
      softwares: [],
      software: 0,
      systems: [],
      prestacion: 0,
      prestaciones: [],
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      talla: 0,
      tallas: [],
      error: false,
      descripcion: "",
      frecuencia: 0,
      addFuncion: false,
      editFuncion: false,
      addHabilidad: false,
      editHabilidad: false,
      adviceFuncion: false,
      adviceHabilidad: false,
      arregloEquipo: [],
      arregloUniforme: [],
      arregloIdioma: [],
      arregloSoftware: [],
      arregloTrabajo: [],
      tamArregloEquipo: 0,
      tamArregloUniforme: 0,
      tamArregloIdioma: 0,
      tamArregloSoftware: 0,
      tamArregloTrabajo: 0,
      min: 0,
      max: 15,
      range: [0, 0],
      minp: 0,
      maxp: 15,
      rangep: [0, 0],
      jobSelect: [],
      nombrePuesto: "",
      uniformes: [],
      uniforme: 0,
      machines: [],
      auxiliarNombre: "",
      advice: false,
      errorFinal: false,
      adviceFunciones: false,
      auxiliarFuncion: false,
      auxiliarHabilidad: false,
      banderaPost: false,
      idRequisicion: 0,
      fecha_minimo: "",
    };
  },
  methods: {
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    isNumberPoint: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode == 46) {
        return true;
      }
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    revisarEquipo() {
      var aux = this.arregloEquipo.length;
      var auxiliar = this.arregloSoftware.length;
      if (auxiliar - 1 != 0) {
      } else {
        for (var i = 0; i < aux; i++) {
          this.arregloEquipo.pop();
        }
      }
    },
    regresarVacante() {
      this.numeroVacante = this.numeroVacanteAux;
      this.advice = false;
    },
    validarVacantes() {
      if (this.numeroVacante > this.numeroVacanteAux) {
        this.titulo = "Vacantes";
        this.respuesta =
          "El numero de vacantes a solicitar es mayor a las vacantes disponibles.¿Esta seguro de seguir?";
        this.advice = true;
      }
    },
    back() {
      this.confirmationFinal = false;
      this.picker = "";
      this.$router.push("/requisitions");
    },
    /*Se obtiene la estructura correcta de cada arreglo para enviar a API*/
    obtenerArreglosFinales(nombreTab) {
      var arregloAuxiliarIdioma = [];
      this.arregloIdioma.forEach((value, index) => {
        delete value.id;
        arregloAuxiliarIdioma.push(value);
      });
      this.arregloIdioma = arregloAuxiliarIdioma;
      var arregloAuxiliarSoftware = [];
      this.arregloSoftware.forEach((value, index) => {
        delete value.id;
        arregloAuxiliarSoftware.push(value);
      });
      this.arregloSoftware = arregloAuxiliarSoftware;
      var arregloAuxiliarEquipo = [];
      this.arregloEquipo.forEach((value, index) => {
        delete value.id;
        arregloAuxiliarEquipo.push(value);
      });
      this.arregloEquipo = arregloAuxiliarEquipo;
      var arregloAuxiliarUniforme = [];
      this.arregloUniforme.forEach((value, index) => {
        delete value.id;
        arregloAuxiliarUniforme.push({
          uniformeId: value.uniformeId,
          tallaId: value.tallaId,
          cantidad: parseInt(value.cantidad),
        });
      });
      this.arregloUniforme = arregloAuxiliarUniforme;
      var arregloAuxiliarHerramienta = [];
      this.arregloTrabajo.forEach((value, index) => {
        delete value.id;
        arregloAuxiliarHerramienta.push(value);
      });
      this.arregloTrabajo = arregloAuxiliarHerramienta;
      this.api(nombreTab);
    },
    /*Guarda o actualiza la requisición de talento */
    api(nombreTab) {
      if (this.banderaPost == false) {
        this.show = true;
        axios
          .post(
            Server + "/requisicionesVacantes",
            {
              EmpleadoSolicitaId: this.solicitante,
              PuestoVacanteId: this.puesto,
              CantidadVacantes: this.numeroVacante,
              TipoContratoId: this.contrato == 0 ? null : this.contrato,
              TipoSalarioId: this.salario == 0 ? null : this.salario,
              TipoSueldoMinimo: this.rango,
              TipoSueldo: this.neto,
              RangoSueldoMin: parseFloat(this.r1),
              RangoSueldoMax: parseFloat(this.r2),
              TipoVacanteId: this.vacante == 0 ? null : this.vacante,
              PaquetePrestacionesId:
                this.prestacion == 0 ? null : this.prestacion,
              RangoEdadMin: parseInt(this.edad1),
              RangoEdadMax: parseInt(this.edad2),
              GeneroId: this.genero == 0 ? null : this.genero,
              ExperienciaAniosMin: Math.min.apply(null, this.range),
              ExperienciaAniosMax: Math.max.apply(null, this.range),
              ExperienciaAniosPuestoMin: Math.min.apply(null, this.rangep),
              ExperienciaAniosPuestoMax: Math.max.apply(null, this.rangep),
              EstadoCivilId: this.estado == 0 ? null : this.estado,
              EscolaridadId: this.escolaridad == 0 ? null : this.escolaridad,
              FechaRequeridaIngreso: this.picker,
              DatosCorrectos: this.terminos,
              Idiomas: this.arregloIdioma,
              Software: this.arregloSoftware,
              EquiposComputo: this.arregloEquipo,
              Uniformes: this.arregloUniforme,
              EquiposTrabajo: this.arregloTrabajo,
            },
            {
              headers: {
                Authorization: localStorage.token,
              },
            }
          )
          .then((response) => {
            //console.log(response);
            this.idRequisicion = response.data.id;
            this.banderaPost = true;
            this.titulo = "Requisición enviada";
            this.respuesta = "Los datos fueron almacenados correctamente";
            this.show = false;
            this.confirmationFinal = true;
            if (nombreTab == "completo") {
              this.back();
            } else {
              this.tabb = nombreTab;
            }
          })
          .catch((e) => {
            console.log(e);
            this.show = false;
            if (e.response.status == 401) {
              this.expiration = true;
            } else {
              this.titulo = "Requisición fallida";
              this.respuesta =
                "La requisición no fue enviada. Vuevalo a intentar más tarde.";
              this.confirmationFinal = true;
            }
          });
      } else {
        this.show = true;
        axios
          .put(
            Server + "/requisicionesVacantes/" + this.idRequisicion,
            {
              Id: this.idRequisicion,
              EmpleadoSolicitaId: this.solicitante,
              PuestoVacanteId: this.puesto,
              CantidadVacantes: this.numeroVacante,
              TipoContratoId: this.contrato == 0 ? null : this.contrato,
              TipoSalarioId: this.salario == 0 ? null : this.salario,
              TipoSueldoMinimo: this.rango,
              TipoSueldo: this.neto,
              RangoSueldoMin: parseFloat(this.r1),
              RangoSueldoMax: parseFloat(this.r2),
              TipoVacanteId: this.vacante == 0 ? null : this.vacante,
              PaquetePrestacionesId:
                this.prestacion == 0 ? null : this.prestacion,
              RangoEdadMin: parseInt(this.edad1),
              RangoEdadMax: parseInt(this.edad2),
              GeneroId: this.genero == 0 ? null : this.genero,
              ExperienciaAniosMin: Math.min.apply(null, this.range),
              ExperienciaAniosMax: Math.max.apply(null, this.range),
              ExperienciaAniosPuestoMin: Math.min.apply(null, this.rangep),
              ExperienciaAniosPuestoMax: Math.max.apply(null, this.rangep),
              EstadoCivilId: this.estado == 0 ? null : this.estado,
              EscolaridadId: this.escolaridad == 0 ? null : this.escolaridad,
              FechaRequeridaIngreso: this.picker,
              DatosCorrectos: this.terminos,
              Idiomas: this.arregloIdioma,
              Software: this.arregloSoftware,
              EquiposComputo: this.arregloEquipo,
              Uniformes: this.arregloUniforme,
              EquiposTrabajo: this.arregloTrabajo,
              EstatusId: 1,
            },
            {
              headers: {
                Authorization: localStorage.token,
              },
            }
          )
          .then((response) => {
            this.titulo = "Requisición enviada";
            this.respuesta = "Los datos fueron actualizados correctamente";
            this.show = false;
            this.confirmationFinal = true;
            if (nombreTab == "completo") {
              this.back();
            } else {
              this.tabb = nombreTab;
            }
          })
          .catch((e) => {
            console.log(e);
            this.show = false;
            if (e.response.status == 401) {
              this.expiration = true;
            } else {
              this.titulo = "Requisición fallida";
              this.respuesta =
                "La requisición no fue enviada. Vuevalo a intentar más tarde.";
              this.confirmationFinal = true;
            }
          });
      }
    },
    validacionFinal() {
      this.errors = [];
      if (this.enterprise == 0) {
        this.errors.push("Se debe especificar la razón social.");
      }
      if (this.area == 0) {
        this.errors.push("Se debe especificar el área.");
      }
      if (this.puesto == 0) {
        this.errors.push("Se debe especificar el puesto de la vacante.");
      }
      if (this.contrato == 0) {
        this.errors.push("Se debe especificar el tipo de contrato.");
      }
      if (this.salario == 0) {
        this.errors.push("Se debe especificar el tipo de salario.");
      }
      if (this.rango == 0 || this.neto == 0) {
        this.errors.push("Se debe especificar el tipo de sueldo.");
      }
      if (this.r1 == 0 || this.r1 == " " || this.r1 == "") {
        this.errors.push("Se debe especificar el rango inicial de salario.");
      }
      if (this.r2 == 0 || this.r2 == " " || this.r2 == "") {
        this.errors.push("Se debe especificar el rango final de salario.");
      }
      if (this.vacante == 0) {
        this.errors.push("Se debe especificar el tipo de vacante.");
      }
      if (this.prestacion == 0) {
        this.errors.push("Se debe especificar el tipo de prestación.");
      }
      if (this.genero == 0) {
        this.errors.push("Se debe especificar el género.");
      }
      if (this.estado == 0) {
        this.errors.push("Se debe especificar el estado civil.");
      }
      if (this.escolaridad == 0) {
        this.errors.push("Se debe especificar la escolaridad.");
      }
      if (this.edad1 < 18 || this.edad2 < 18) {
        this.errors.push("Se debe especificar la edad para el postulante.");
      }
      if (this.arregloIdioma.length != 0) {
        this.arregloIdioma.forEach((value, index) => {
          if (value.idiomaId == 0 || value.nivelIdiomaId == 0) {
            this.errors.push("Se debe de especificar el idioma y nivel.");
          }
        });
      }
      if (this.arregloSoftware.length != 0) {
        this.arregloSoftware.forEach((value, index) => {
          if (
            value.softwareId == 0 ||
            value.nivelSoftwareMaxId == 0 ||
            value.nivelSoftwareMinId == 0
          ) {
            this.errors.push(
              "Se debe de especificar el software con sus respectivos niveles."
            );
          }
        });
      }
      if (this.arregloEquipo.length != 0) {
        this.arregloEquipo.forEach((value, index) => {
          if (value.equipoComputoId == 0 || value.sistemaOperativoId == 0) {
            this.errors.push("Se debe de especificar el tipo de equipo y S.O.");
          }
        });
      }
      if (this.arregloUniforme.length != 0) {
        this.arregloUniforme.forEach((value, index) => {
          if (
            value.tallaId == 0 ||
            value.uniformeId == 0 ||
            value.cantidad == 0
          ) {
            this.errors.push(
              "Se debe de especificar el tipo de uniforme, talla y cantidad."
            );
          }
        });
      }
      if (this.arregloTrabajo.length != 0) {
        this.arregloTrabajo.forEach((value, index) => {
          if (value.herramientaId == 0) {
            this.errors.push(
              "Se debe de especificar la maquina y/o herramienta."
            );
          }
        });
      }
      if (this.picker == null) {
        this.errors.push(
          "Seleccionar fecha requerida de ingreso para la postulante."
        );
      }
      if (this.errors.length != 0) {
        this.titulo = "Requisición de vacante";
        this.respuesta = "Favor de corregir el(los) siguiente(s) error(es):";
        this.errorFinal = true;
      } else {
        this.avisoTermino = true;
      }
    },
    funcionHabilidad() {
      if (this.habilities.length == 0) {
        this.titulo = "Habilidades";
        this.respuesta =
          "¿No se ha asignado ninguna Habilidad desea continuar?";
        this.auxiliarHabilidad = true;
        this.adviceFunciones = true;
      } else this.tabb = "fecha";
    },
    funcionFunciones() {
      if (this.activities.length == 0) {
        this.titulo = "Funciones";
        this.respuesta =
          "¿No se ha asignado ninguna Actividad desea continuar?";
        this.auxiliarFuncion = true;
        this.adviceFunciones = true;
      } else this.tabb = "habilidades";
    },
    mantenerTab() {
      this.adviceFunciones = false;
      if (this.auxiliarHabilidad == true && this.auxiliarFuncion == false) {
        this.auxiliarHabilidad = false;
        this.tabb = "habilidades";
      } else {
        this.auxiliarFuncion = false;
        this.tabb = "funciones";
      }
    },
    cambiarTab() {
      this.adviceFunciones = false;
      if (this.auxiliarHabilidad == true) {
        this.auxiliarHabilidad = false;
        this.tabb = "fecha";
      }
      if (this.auxiliarFuncion == true) {
        this.auxiliarFuncion = false;
        this.tabb = "habilidades";
      }
    },
    habilidadFecha() {
      this.tabb = "fecha";
    },
    obtenerAreas() {
      this.areas = [];
      this.area = 0;
      this.puesto = 0;
      this.jobs = 0;
      this.jobSelect = [];
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.listarPrestaciones();
    },
    obtenerPuestos() {
      this.jobs = [];
      this.employee = 0;
      this.puesto = 0;
      this.numeroVacante = 0;
      this.numeroVacanteAux = 0;
      this.jobSelect = [];
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (
              value.empresaId == this.enterprise &&
              value.areaReportaId == this.area
            ) {
              this.jobs.push({
                id: value.id,
                nombrePuesto: value.nombrePuesto,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerNombrePuesto(item) {
      this.jobSelect = [];
      this.show = true;
      axios
        .get(Server + "/puestos/" + this.puesto, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.jobSelect = response.data;
          this.auxiliarNombre = this.jobSelect.nombrePuesto;
          if (this.jobSelect.puestoReportaId == null) {
            this.jobSelect.puestoReportaId = "Ninguno";
          } else {
            var jefe = this.employees.find(
              (jefe) => jefe.puesto.id == this.jobSelect.puestoReportaId
            );
            this.jobSelect.puestoReportaId = jefe.puesto.nombrePuesto;
          }
          this.obtenerVacantes();
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.obtenerFunciones();
      this.obtenerHabilidades();
    },
    /*Obtiene las funciones a cumplir del puesto que será requisitado */
    obtenerFunciones() {
      this.show = true;
      axios
        .get(Server + "/funcionesPuestos/por-puesto/" + this.puesto, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log("funcionesPuestos", response);
          this.activities = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    /*Obtiene las habilidades a cumplir del puesto que será requisitado */
    obtenerHabilidades() {
      this.show = true;
      axios
        .get(Server + "/habilidadesPuestos/por-puesto/" + this.puesto, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log("habilidadesPuestos", response);
          this.habilities = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    //NUEVA habilidad
    guardarHabilidad() {
      //console.log("Ingreso a Guardar habilidad");
      this.titulo = "Habilidad";
      this.addHabilidad = false;
      this.show = true;
      axios
        .post(
          Server + "/habilidadesPuestos",
          {
            PuestoId: this.puesto,
            DescripcionHabilidad: this.descripcion,
            Frecuencia: this.frecuencia,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.descripcion = "";
          this.frecuencia = "";
          this.respuesta = "La Habilidad fue guardada con éxito";
          this.show = false;
          this.confirmation = true;
          this.obtenerHabilidades();
        })
        .catch((e) => {
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            //console.log(e);
            this.descripcion = "";
            this.frecuencia = "";
            this.respuesta = "La Habilidad no fue almacenada";
            this.confirmation = true;
          }
        });
    },
    //NUEVA funcion
    guardarFuncion() {
      //console.log("Ingreso a Guardar funcion");
      this.titulo = "Funcion";
      this.addFuncion = false;
      this.show = true;
      axios
        .post(
          Server + "/funcionesPuestos",
          {
            PuestoId: this.puesto,
            DescripcionActividad: this.descripcion,
            Frecuencia: this.frecuencia,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.descripcion = "";
          this.frecuencia = "";
          this.respuesta = "La Función fue guardada con éxito";
          this.show = false;
          this.confirmation = true;
          this.obtenerFunciones();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.descripcion = "";
            this.frecuencia = "";
            this.respuesta = "La Función no fue almacenada";
            this.confirmation = true;
          }
        });
    },
    //EDITAR FUNCION
    editarFuncion() {
      //console.log("Ingreso a editar Funcion con id" + this.id);
      this.titulo = "Función";
      this.editHabilidad = false;
      this.show = true;
      axios
        .put(
          Server + "/funcionesPuestos/" + this.id,
          {
            Id: this.id,
            PuestoId: this.puesto,
            DescripcionActividad: this.descripcion,
            Frecuencia: this.frecuencia,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.descripcion = "";
          this.frecuencia = "";
          this.respuesta = "La Función fue actualizada con éxito";
          this.show = false;
          this.confirmation = true;
          this.obtenerFunciones();
        })
        .catch((e) => {
          this.show = false;
          console.log(e);
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.descripcion = "";
            this.frecuencia = "";
            this.respuesta = "La Función no fue actualizada";
            this.confirmation = true;
          }
        });
    },
    //EDITAR HABILIDAD
    editarHabilidad() {
      //console.log("Ingreso a editar habilidad con id" + this.id);
      this.titulo = "Habilidad";
      this.editHabilidad = false;
      this.show = true;
      axios
        .put(
          Server + "/habilidadesPuestos/" + this.id,
          {
            Id: this.id,
            PuestoId: this.puesto,
            DescripcionHabilidad: this.descripcion,
            Frecuencia: this.frecuencia,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.descripcion = "";
          this.frecuencia = "";
          this.respuesta = "La Habilidad fue actualizada con éxito";
          this.show = false;
          this.confirmation = true;
          this.obtenerHabilidades();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.descripcion = "";
            this.frecuencia = "";
            this.respuesta = "La Habilidad no fue actualizada";
            this.confirmation = true;
          }
        });
    },
    inicio() {
      this.errors = [];
      if (this.enterprise == 0) {
        this.errors.push("Se debe especificar la razón social.");
      }
      if (this.area == 0) {
        this.errors.push("Se debe especificar el área.");
      }
      if (this.puesto == 0) {
        this.errors.push("Se debe especificar el puesto de la vacante.");
      }
      if (this.errors.length == 0) {
        this.obtenerArreglosFinales("puesto");
        //this.tabb = "puesto";
      } else {
        this.titulo = "Error en datos del Solicitante";
        this.respuesta = "Por favor corrige el(los) siguiente(s) error(es):";
        this.error = true;
      }
    },
    //validacion inicial
    validacionInicial() {
      this.errors = [];
      if (this.contrato == 0) {
        this.errors.push("Se debe especificar el tipo de contrato.");
      }
      if (this.salario == 0) {
        this.errors.push("Se debe especificar el tipo de salario.");
      }
      if (this.rango == 0 || this.neto == 0) {
        this.errors.push("Se debe especificar el tipo de sueldo.");
      }
      if (this.r1 == 0 || this.r1 == " " || this.r1 == "") {
        this.errors.push("Se debe especificar el rango inicial de salario.");
      }
      if (this.r2 == 0 || this.r2 == " " || this.r2 == "") {
        this.errors.push("Se debe especificar el rango final de salario.");
      }
      if (this.vacante == 0) {
        this.errors.push("Se debe especificar el tipo de vacante.");
      }
      if (this.prestacion == 0) {
        this.errors.push("Se debe especificar el tipo de prestación.");
      }
      if (this.genero == 0) {
        this.errors.push("Se debe especificar el género.");
      }
      if (this.estado == 0) {
        this.errors.push("Se debe especificar el estado civil.");
      }
      if (this.escolaridad == 0) {
        this.errors.push("Se debe especificar la escolaridad.");
      }
      if (this.edad1 < 18 || this.edad2 < 18) {
        this.errors.push("Se debe especificar la edad para el postulante.");
      }
      if (this.arregloIdioma.length != 0) {
        this.arregloIdioma.forEach((value, index) => {
          if (value.idiomaId == 0 || value.nivelIdiomaId == 0) {
            this.errors.push("Se debe de especificar el idioma y nivel.");
          }
        });
      }
      if (this.arregloSoftware.length != 0) {
        this.arregloSoftware.forEach((value, index) => {
          if (
            value.softwareId == 0 ||
            value.nivelSoftwareMaxId == 0 ||
            value.nivelSoftwareMinId == 0
          ) {
            this.errors.push(
              "Se debe de especificar el software con sus respectivos niveles."
            );
          }
        });
      }
      if (this.errors.length == 0) {
        this.obtenerArreglosFinales("requisiciones");
        //this.tabb = "requisiciones";
      } else {
        this.titulo = "Error en datos de Puesto";
        this.respuesta = "Por favor corrige el(los) siguiente(s) error(es):";
        this.error = true;
      }
    },
    validacionRequisicion() {
      this.errors = [];
      if (this.arregloEquipo.length != 0) {
        this.arregloEquipo.forEach((value, index) => {
          if (value.equipoComputoId == 0 || value.sistemaOperativoId == 0) {
            this.errors.push("Se debe de especificar el tipo de equipo y S.O.");
          }
        });
      }
      if (this.arregloUniforme.length != 0) {
        this.arregloUniforme.forEach((value, index) => {
          if (
            value.tallaId == 0 ||
            value.uniformeId == 0 ||
            value.cantidad == 0
          ) {
            this.errors.push(
              "Se debe de especificar el tipo de uniforme, talla y cantidad."
            );
          }
        });
      }
      if (this.arregloTrabajo.length != 0) {
        this.arregloTrabajo.forEach((value, index) => {
          if (value.herramientaId == 0) {
            this.errors.push(
              "Se debe de especificar la maquina y/o herramienta."
            );
          }
        });
      }
      if (this.errors.length == 0) {
        this.obtenerArreglosFinales("funciones");
        //this.tabb = "funciones";
      } else {
        this.titulo = "Requisición de Material";
        this.respuesta = "Favor de corregir el(los) siguiente(s) error(es):";
        this.error = true;
      }
    },
    validacionFuncion: function () {
      this.errors = [];
      if (this.descripcion == "" || this.descripcion == " ") {
        this.errors.push("Se debe la descripción de las funciones.");
      }
      if (this.frecuencia == "" || this.frecuencia == " ") {
        this.errors.push("Se debe especificar la frecuencia.");
      }
      if (this.errors.length == 0) {
        this.editFuncion = false;
        this.vacio = false;
        return this.editarFuncion();
      } else {
        this.vacio = true;
      }
    },
    validacionFuncionNueva: function () {
      this.errors = [];
      if (this.descripcion == "" || this.descripcion == " ") {
        this.errors.push("Se debe la descripción de las funciones.");
      }
      if (this.frecuencia == "" || this.frecuencia == " ") {
        this.errors.push("Se debe especificar la frecuencia.");
      }
      if (this.errors.length == 0) {
        this.editFuncion = false;
        this.vacio = false;
        return this.guardarFuncion();
      } else {
        this.vacio = true;
      }
    },
    validacionHabilidad: function () {
      this.errors = [];
      if (this.descripcion == "" || this.descripcion == " ") {
        this.errors.push("Se debe la descripción de las funciones.");
      }
      if (this.frecuencia == "" || this.frecuencia == " ") {
        this.errors.push("Se debe especificar la frecuencia.");
      }
      if (this.errors.length == 0) {
        this.editHabilidad = false;
        this.vacio = false;
        return this.editarHabilidad();
      } else {
        this.vacio = true;
      }
    },
    validacionHabilidadNueva: function () {
      this.errors = [];
      if (this.descripcion == "" || this.descripcion == " ") {
        this.errors.push("Se debe la descripción de las funciones.");
      }
      if (this.frecuencia == "" || this.frecuencia == " ") {
        this.errors.push("Se debe especificar la frecuencia.");
      }
      if (this.errors.length == 0) {
        this.editFuncion = false;
        this.vacio = false;
        return this.guardarHabilidad();
      } else {
        this.vacio = true;
      }
    },
    editItemHabilidad(item) {
      //console.log("Ingreso a editar habilidad" + item.id);
      this.id = item.id;
      this.descripcion = item.descripcionHabilidad;
      this.frecuencia = item.frecuencia;
      this.editHabilidad = true;
    },
    editItemFuncion(item) {
      //console.log("Ingreso a editar funcion" + item.id);
      this.id = item.id;
      this.descripcion = item.descripcionActividad;
      this.frecuencia = item.frecuencia;
      this.editFuncion = true;
    },
    temporalHabilidades(item) {
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar la habilidad de " +
        item.descripcionHabilidad +
        " ?";
      this.adviceHabilidad = true;
    },
    temporalFuncion(item) {
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar la función de " +
        item.descripcionActividad +
        " ?";
      this.adviceFuncion = true;
    },
    deleteHabilidades() {
      this.adviceHabilidad = false;
      //console.log("Ingreso a eliminar habilidad con id: " + this.id);
      this.titulo = "Habilidad";
      /* this.respuesta = "El elemento fue eliminado con éxito";
      this.confirmation = true; */
      this.show = true;
      axios
        .delete(Server + "/habilidadesPuestos/" + this.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.show = false;
          this.id = "";
          this.respuesta = "El elemento fue eliminado con éxito";
          this.confirmation = true;
          this.obtenerHabilidades();
        })
        .catch((e) => {
          this.show = false;
          console.log(e);
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "El elemento no fue eliminado";
            this.confirmation = true;
          }
        });
    },
    deleteFunciones() {
      this.adviceFuncion = false;
      //console.log("Ingreso a eliminar funcion con id: " + this.id);
      this.titulo = "Función";
      /* this.respuesta = "El elemento fue eliminado con éxito";
      this.confirmation = true; */
      this.show = true;
      axios
        .delete(Server + "/funcionesPuestos/" + this.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.show = false;
          this.id = "";
          this.respuesta = "El elemento fue eliminado con éxito";
          this.confirmation = true;
          this.obtenerFunciones();
        })
        .catch((e) => {
          this.show = false;
          console.log(e);
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "El elemento no fue eliminado";
            this.confirmation = true;
          }
        });
    },
    listarEmpresas() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarEscolaridad() {
      this.show = true;
      axios
        .get(Server + "/escolaridad", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.escolaridades = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarNiveles() {
      this.show = true;
      axios
        .get(Server + "/nivelesIdioma", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.niveles = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarSoftware() {
      this.show = true;
      axios
        .get(Server + "/software", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.softwares = response.data;
          this.show = false;
        })
        .catch((e) => {
          // console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/sistemasOperativos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.systems = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });

      this.show = true;
      axios
        .get(Server + "/equiposComputo", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.equipments = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/nivelesSoftware", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.softwareLevel = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarIdiomas() {
      this.show = true;
      axios
        .get(Server + "/idiomas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.idiomas = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarEstadoCivil() {
      this.show = true;
      axios
        .get(Server + "/estadosCivil", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          // console.log(response);
          this.estados = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarVacantes() {
      this.show = true;
      axios
        .get(Server + "/vacantes", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.vacantes = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarGenero() {
      this.show = true;
      axios
        .get(Server + "/genero", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.generos = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(response);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarSalarios() {
      this.show = true;
      axios
        .get(Server + "/salarios", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.salarios = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarContratos() {
      this.show = true;
      axios
        .get(Server + "/contratos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.contratos = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(response);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listar() {
      this.show = true;
      //Listado de funciones
      axios
        .get(Server + "/funciones", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.activities = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      //Listado de habilidades
      this.show = true;
      axios
        .get(Server + "/habilidades", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.habilities = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    /*Agrega al arreglo de Herramientas de trabajo la nueva herramienta */
    cargaTrabajo: function () {
      this.tamArregloTrabajo = this.tamArregloTrabajo.length + 1;
      //console.log("el id en herramienta de trabajo es " + this.tamArregloTrabajo);
      this.arregloTrabajo.push({
        id: this.tamArregloTrabajo,
        herramientaId: 0,
      });
    },
    /* Valida si el puesto requiere asignación de Equipo de Computo*/
    validacionEquipo() {
      /*PRIMERO SE VALIDARA QUE SEPA MANEJAR ALGUN SOFTWARE PARA ASIGNAR UN EQUIPO SI EL ARRAY ES VACIO ENTONCES POP UP*/
      if (this.arregloSoftware.length == 0) {
        this.titulo = "Asignación de Equipo";
        this.respuesta =
          "Es necesario que el puesto solicite el manejo de un software para asignar un equipo.";
        this.error = true;
      } else {
        this.cargaEquipo();
      }
    },
    /*Agrega al arreglo de Equipo de computo un nuevo equipo a solicitar para el nuevo Puesto */
    cargaEquipo: function () {
      this.tamArregloEquipo = this.arregloEquipo.length + 1;
      //console.log("el id en equipo es " + this.tamArregloEquipo);
      this.arregloEquipo.push({
        id: this.tamArregloEquipo,
        equipoComputoId: 0,
        sistemaOperativoId: 0,
      });
    },
    /*Agrega al arreglo de Uniformes el uniforme para el nuevo puesto que se esta solicitando */
    cargaUniforme: function () {
      this.tamArregloUniforme = this.arregloUniforme.length + 1;
      //console.log("el id en uniforme es " + this.tamArregloUniforme);
      this.arregloUniforme.push({
        id: this.tamArregloUniforme,
        uniformeId: 0,
        tallaId: 0,
        cantidad: 0,
      });
    },
    quitarSoftware: function () {
      this.arregloSoftware.pop();
    },
    quitarUniforme: function () {
      this.arregloUniforme.pop();
    },
    quitarEquipo: function () {
      this.arregloEquipo.pop();
    },
    quitarTrabajo: function () {
      this.arregloTrabajo.pop();
    },
    quitarIdioma: function () {
      this.arregloIdioma.pop();
    },
    /*Agrega un Idioma necesario para la requisicion del Puesto */
    cargaIdioma: function () {
      this.tamArregloIdioma = this.arregloIdioma.length + 1;
      //console.log("el id en idioma es " + this.tamArregloIdioma);
      this.arregloIdioma.push({
        id: this.tamArregloIdioma,
        idiomaId: 0,
        nivelIdiomaId: 0,
      });
    },
    /*Agrega al arreglo de Software el manejo de un software necesario para el puesto */
    cargaSoftware: function () {
      this.tamArregloSoftware = this.arregloSoftware.length + 1;
      //console.log("el id en software es " + this.tamArregloSoftware);
      this.arregloSoftware.push({
        id: this.tamArregloSoftware,
        softwareId: 0,
        nivelSoftwareMinId: 0,
        nivelSoftwareMaxId: 0,
      });
    },
    cancelFuncion() {
      this.descripcion = "";
      this.frecuencia = 0;
      this.addFuncion = false;
    },
    cancelHabilidad() {
      this.descripcion = "";
      this.frecuencia = 0;
      this.addHabilidad = false;
    },
    auxFuncion() {
      this.descripcion = "";
      this.frecuencia = 0;
      this.editFuncion = false;
    },
    auxHabilidad() {
      this.descripcion = "";
      this.frecuencia = 0;
      this.editHabilidad = false;
    },
    listarTallas() {
      this.show = true;
      axios
        .get(Server + "/tallas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.tallas = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarPrestaciones() {
      this.prestaciones = [];
      this.prestacion = 0;
      this.show = true;
      axios
        .get(Server + "/paquetesPrestaciones/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.prestaciones = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarUniformes() {
      this.show = true;
      axios
        .get(Server + "/uniforme", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.uniformes = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarHerramientas() {
      this.show = true;
      axios
        .get(Server + "/maquinas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.machines = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    /*Obtiene el numero de vacantes de un determinado puesto */
    obtenerVacantes() {
      this.show = true;
      this.numeroVacanteAux = 0;
      this.numeroVacante = 0;
      axios
        .get(Server + "/dashboard/inventario-puestos/" + this.enterprise + "/" + this.area, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var vacantes = response.data;
          vacantes.forEach((value, index) => {
            if (value.puesto == this.auxiliarNombre) {
              this.numeroVacante = value.vacantes;
              this.numeroVacanteAux = value.vacantes;
            }
          });
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerEmpleados() {
      this.show = true;
      axios
        .get(Server + "/organization/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.employees = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    inicializar() {
      this.listarVacantes();
      this.listarGenero();
      this.listarSalarios();
      this.listarContratos();
      this.listarEstadoCivil();
      this.listarEscolaridad();
      this.listarIdiomas();
      this.listarNiveles();
      this.listarSoftware();
      this.listarEmpresas();
      this.listarTallas();
      this.listarUniformes();
      this.listarHerramientas();
    },
    fechasMinimo() {
      var fecha = new Date();
      var anio = fecha.getFullYear();
      var dia = fecha.getDate();
      var _mes = fecha.getMonth(); //0 al 11
      _mes = _mes + 1; //de 1 al 12
      if (_mes < 10) {
        //agrega un 0
        var mes = "0" + _mes;
      } else {
        var mes = _mes.toString();
      }
      this.fecha_minimo = anio + "-" + mes + "-" + dia; // Nueva variable
    },
  },
  created() {
    this.fechasMinimo();
    this.inicializar();
    this.obtenerAreas();
    this.obtenerEmpleados();
  },
};
</script>
<style scoped>
html,
body {
  touch-action: none;
}
.md-theme-default a:not(.md-button) {
  color: white;
}
</style>